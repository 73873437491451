import { graphql, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { brand1 } from '../design/colors/brand';
import { CenteredSliceContentContainer } from '../design/containers/sliceContainer/CenteredSliceContentContainer';
import { SliceContainer } from '../design/containers/sliceContainer/SliceContainer';
import { breakpointSmall } from '../design/responsive/breakpoints';
import Spacing, { spacings } from '../design/spacing/Spacing';
import { Display, Heading3 } from '../design/typography/Typography';
import CloneChild from '../design/utils/CloneChild';
import PageContainer from '../pageContainers/PageContainer';
import withBottomSpacing from '../slices/utils/withBottomSpacing';
import { WithClassName } from '../utilityTypes';

const DisplayHeading = styled(Display)`
  color: ${brand1};
  margin: 0;
`;

const BrandMarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const BrandMarkImageStylesProvider = styled(CloneChild)`
  max-width: 317px;
  max-height: 317px;
  width: 100%;
  height: 100%;
`;

export const TwoColumnCenter = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacings['24px']};
  grid-auto-rows: 1fr;
  align-items: center;
  gap: 0;
  ${breakpointSmall(
    css`
      grid-template-columns: 1fr 1fr;
    `
  )};
`;
const ContentContainer = styled(CenteredSliceContentContainer)`
  min-height: 400px;
`;

const DualToneBrandMark: FC<WithClassName> = ({ className }) => (
  <BrandMarkContainer className={className}>
    <BrandMarkImageStylesProvider>
      <StaticImage src="./../images/dual-tone-icon.png" alt="dual tone weel icon" />
    </BrandMarkImageStylesProvider>
  </BrandMarkContainer>
);

const NotFoundSlice = withBottomSpacing(({ className }: WithClassName) => (
  <SliceContainer className={className} background="brand4WaveMidnightRight">
    <ContentContainer>
      <TwoColumnCenter>
        <DualToneBrandMark />
        <div>
          <DisplayHeading>404</DisplayHeading>
          <Spacing top="8px">
            <Heading3>Page not found</Heading3>
          </Spacing>
        </div>
      </TwoColumnCenter>
    </ContentContainer>
  </SliceContainer>
));

/**
 * 404 page.
 */
const PageNotFound: FC<PageProps<Queries.NotFoundPageQuery>> = ({ data }) => {
  const headerId = data.prismic404Page?.data.header?.id || undefined;
  const footerId = data.prismic404Page?.data.footer?.id || undefined;

  return (
    <PageContainer headerId={headerId} footerId={footerId}>
      <NotFoundSlice />
    </PageContainer>
  );
};

export default withPrismicUnpublishedPreview(PageNotFound);

export const query = graphql`
  query NotFoundPage {
    prismic404Page {
      data {
        ...NotFoundPageHeader
        ...NotFoundPageFooter
      }
    }
  }
`;
